<script setup>
import { blocks } from "@/constants/dynamicBlocks.json";

const SPACERS = {
  0: '0px',
  1: '10px',
  2: '20px',
  3: '30px',
  4: '40px',
  5: '50px',
  6: '60px',
  7: '70px',
  8: '80px',
  9: '90px',
};

const props = defineProps({
  sections: Object,
  id: String,
  location: Object,
  pulseData: Object,
  gridData: Object
});
const refs = ref([]);
const components = props.sections.map(element => {
  const options = element.options || {};
  const defaults = element.defaults || {};
  const mobilePaddingTopValue = options.mobilePaddingTop !== 0 ? SPACERS[options.mobilePaddingTop] || '0px' : SPACERS[options.paddingTop] || '0px';
  const desktopPaddingTopValue = options.mobilePaddingTop !== 0 ? SPACERS[options.paddingTop] || '0px' : mobilePaddingTopValue;

  const mobilePaddingBottomValue = options.mobilePaddingBottom !== 0 ? SPACERS[options.mobilePaddingBottom] || '0px' : SPACERS[options.paddingBottom] || '0px';
  const desktopPaddingBottomValue = options.mobilePaddingBottom !== 0 ? SPACERS[options.paddingBottom] || '0px' : mobilePaddingBottomValue;

  const componentName = blocks[element.component];
  const component = {
    reference: resolveComponent(componentName),
    data: element,
    mobilePaddingTopValue,
    desktopPaddingTopValue,
    mobilePaddingBottomValue,
    desktopPaddingBottomValue,
    backgroundColor: options.backgroundColor ? 'bg-' + options.backgroundColor.toLowerCase().replace(/\s+/g, '-') : 'bg-white',
    showComponent: defaults.showComponent || false,
    showDivider: options.showDivider || false,
    // refs is a reference to all the dynamic blocks in the current page
    refs: refs
  };
  return component;
});
const emit = defineEmits(['nestedStep', 'studentName', 'validateStep'])
const nextStep = (value) => {
 emit('nestedStep', value)
}
const studentName =(value) => {
  emit('studentName', value)
}

const stepValidation = (value) => {
  emit('validateStep', value)
}

</script>

<template>
  <div :id="id">
    <template v-for="component in components">
      <section
        class="section-component"
        :style="{
          '--mobile-padding-top': component.mobilePaddingTopValue,
          '--desktop-padding-top': component.desktopPaddingTopValue,
          '--mobile-padding-bottom': component.mobilePaddingBottomValue,
          '--desktop-padding-bottom': component.desktopPaddingBottomValue
        }"
        :class="component.backgroundColor"
        v-if="component.showComponent"
        ref="sectionRefs"
      >
        <component :is="component.reference" :data="component.data" :location="location" ref="refs" :refs="refs" @nestedStep="nextStep" @studentName="studentName" @validateStep="stepValidation" :pulseData="pulseData" :gridData="gridData"/>
        <hr class="w-100" v-if="component.showDivider">
      </section>
    </template>
  </div>
</template>

<style lang="scss">
/* Define the spacer values as CSS variables */
:root {
  --spacer-0: 0px;
  --spacer-1: 10px;
  --spacer-2: 20px;
  --spacer-3: 30px;
  --spacer-4: 40px;
  --spacer-5: 50px;
  --spacer-6: 60px;
  --spacer-7: 70px;
  --spacer-8: 80px;
  --spacer-9: 90px;
}

.section-component {
  &:has(#filter-card-product) {
    min-height: 150px;
    @include media-breakpoint-down(md) {
      min-height: 180px;
    }
  }

  &:has(#filter-card-product .heading-row) {
    min-height: 210px;
    @include media-breakpoint-down(md) {
      min-height: 240px;
    }
  }
}

/* Apply padding based on viewport size using media queries */
@include media-breakpoint-up(lg) {
  .section-component {
    padding-top: var(--desktop-padding-top);
    padding-bottom: var(--desktop-padding-bottom);
  }
}

@include media-breakpoint-down(lg) {
  .section-component {
    padding-top: var(--mobile-padding-top);
    padding-bottom: var(--mobile-padding-bottom);
  }
}
</style>
